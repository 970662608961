// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noti-recent {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f5f3f3;
    border-radius: .7em;

}

.noti-recent > div{
    padding: 13px;
    border-radius: .7em;

    transition: all .3s;
}
.noti-recent .date-time {
    color: #b6b3b3;
    line-height: 1.2em;
    text-align: right;
}

.noti-recent img {
    width: 42px;
    height: 42px;
}

.noti-recent > div:hover {
    background-color: #f8f6f6;
}

section.notifications .wrapping{
    padding: 13px;
}
section.notifications h4 {
    font-family: 'poppins';
}
span.linktoshow {
    color: #c43cab;
    cursor: pointer;
}
.fade.inst-feedback .modal-dialog{
    max-width: 600px;
    width: 100%;
}
@media (max-width: 460px){
    .noti-recent img {
        width: 32px;
        height: 32px;
    }

    .noti-recent p,
    .noti-recent span{
        font-size: 12px;
    }

    
    .new-noti img {
        width: 10px;
    }
    section.notifications hr{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Notifications/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,gCAAgC;IAChC,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;;QAEI,eAAe;IACnB;;;IAGA;QACI,WAAW;IACf;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".noti-recent {\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid #f5f3f3;\n    border-radius: .7em;\n\n}\n\n.noti-recent > div{\n    padding: 13px;\n    border-radius: .7em;\n\n    transition: all .3s;\n}\n.noti-recent .date-time {\n    color: #b6b3b3;\n    line-height: 1.2em;\n    text-align: right;\n}\n\n.noti-recent img {\n    width: 42px;\n    height: 42px;\n}\n\n.noti-recent > div:hover {\n    background-color: #f8f6f6;\n}\n\nsection.notifications .wrapping{\n    padding: 13px;\n}\nsection.notifications h4 {\n    font-family: 'poppins';\n}\nspan.linktoshow {\n    color: #c43cab;\n    cursor: pointer;\n}\n.fade.inst-feedback .modal-dialog{\n    max-width: 600px;\n    width: 100%;\n}\n@media (max-width: 460px){\n    .noti-recent img {\n        width: 32px;\n        height: 32px;\n    }\n\n    .noti-recent p,\n    .noti-recent span{\n        font-size: 12px;\n    }\n\n    \n    .new-noti img {\n        width: 10px;\n    }\n    section.notifications hr{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
