import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import signimg from "../images/signupimg.png";
import "./style.css";
import "../global.css";
import logo from "../images/new-logo.png";
import { Link } from "react-router-dom";
import gmplogo from "../images/brand_logo.svg";
import blind from "../images/blind.svg";
import eye from "../images/ph_eye.svg";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axiosInstance from "../utils/http/config/axios";

function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(blind);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(blind);
      setType("password");
    }
  };

  const handlePhoneInputChange = (value) => {
    const phoneNumberObj = parsePhoneNumberFromString(value || "", countryCode);

    if (phoneNumberObj) {
      setCountryCode(phoneNumberObj.countryCallingCode);
      setPhoneNumber(phoneNumberObj.nationalNumber);
    } else {
      setCountryCode("");
      setPhoneNumber("");
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!phoneNumber || !countryCode) {
      setError("Le code du pays doit être au format +XYZ.");
      return;
    }

    if (phoneNumber.length > 15) {
      setError("Le numéro de téléphone doit comporter entre 8 et 15 chiffres");
      return;
    }

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    if (!countryCode) {
      setError("Le code pays est obligatoire.");
      return;
    }

    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError(
        "Votre mot de passe doit comporter au moins 8 caractères, incluant une lettre, un chiffre et un symbole spécial."
      );
      return;
    }

    setIsLoading(true);

    try {
      const response = await axiosInstance.post("/api/students/register", {
        firstName,
        lastName,
        email,
        countryCode: `+${countryCode}`,
        phoneNumber,
        password,
      });

      setSuccessMessage(response.data.message);
      navigate("/welcome", { state: { userEmail: email } });
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError(
          "Une erreur inattendue est survenue. Veuillez réessayer plus tard."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="signup">
      <div className="container-fluid mx-0">
        <div className="row">
          <div className="col-lg-6 p-0">
            <div className="wrap">
              <div className="login-content">
                <div className="logo-card">
                  <img src={logo} width={"100px"} />
                </div>
                <h3>Inscription</h3>
                <p>Créez votre compte pour continuer</p>
                <form onSubmit={handleSignup}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nom"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prénom"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <PhoneInput
                      placeholder="Entrez votre numéro"
                      className="form-control"
                      value={phoneNumber ? `+${countryCode}${phoneNumber}` : ""}
                      onChange={handlePhoneInputChange}
                    />
                  </div>

                  <div className="mb-3 pasword">
                    <input
                      type={type}
                      name="password"
                      className="form-control pasword"
                      placeholder="Mot de passe"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                      required
                    />
                    <span
                      className="flex justify-around items-center psd-icon"
                      onClick={handleToggle}
                    >
                      <img src={icon} alt="Basculer la visibilité" />
                    </span>
                  </div>
                  <div className="mb-3 pasword">
                    <input
                      type={type}
                      name="confirmPassword"
                      className="form-control pasword"
                      placeholder="Confirmer le mot de passe"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="current-password"
                      required
                    />
                    <span
                      className="flex justify-around items-center psd-icon"
                      onClick={handleToggle}
                    >
                      <img src={icon} alt="Basculer la visibilité" />
                    </span>
                  </div>
                  {error && <div className="error-message">{error}</div>}
                  {successMessage && (
                    <div className="success-message">{successMessage}</div>
                  )}
                  <div className="aggrement my-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        // checked={isTermsAccepted}
                        // onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="flexCheckDefault"
                      >
                        je suis d'accord avec les{" "}
                        <Link
                          to="/terms-conditions"
                          style={{ color: "#F85E1E" }}
                        >
                          termes et conditions
                        </Link>
                      </label>
                    </div>
                  </div>
                  <div className="login-btn">
                    <button
                      type="submit"
                      className="btn btn-primary btn-fill"
                      disabled={isLoading}
                    >
                      {isLoading ? "Chargement..." : "S'inscrire"}
                    </button>
                  </div>
                  <p className="my-4">
                    Vous avez déjà un compte?{" "}
                    <Link to="/" style={{ color: "#F85E1E" }}>
                      se connecter
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="right-side-bg">
              <img
                className="top-left"
                src={signimg}
                alt="Signup illustration"
              />
              <div className="toplogo">
                <img src={gmplogo} alt="Logo" />
              </div>
              <div className="img-btm-text">
                <p>Développez vos compétences de conduite</p>
                <h2>
                  Vous pouvez apprendre facilement la conduite{" "}
                  <span>automobile</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
